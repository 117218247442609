<template>
    <main class="dashboard min-h-screen  flex bg-page-bg justify-between max-h-screen max-w-full" :style="themeColors">
        <app-sidebar />
        <section class="flex overflow-hidden flex-1 flex-col">
            <app-header :notificationCount="notificationCount" />
             
           <iframe class="w-full aspect-video hover:aspect-square h-full" :src="this.case_url">
           </iframe>
            <router-view v-if="!isLoading"></router-view>
    </section>
</main>
   </template>
<script>
import appHeader from "@shared/app-header"
import appSidebar from "@/components/app-sidebar"
import { mapActions, mapGetters } from "vuex";
import {getSaasUrl} from "@shared/light-ray/services";
import VueCookies from "vue-cookies";
import axios from "@shared/light-ray/axios";
import ax from "@/axios"





export default {
    data() {
        return {
             
            // case_url: VueCookies.get("case_url")
            case_url: "",
            notificationCount: 0,
            
        }
    },
    components: {
        appSidebar,
        appHeader,
    },
    computed: {
    ...mapGetters(["getThemeColors","getDefaultColors","getClientId"]),
    themeColors() {
      const colors = this.getThemeColors;
      return Object.values(colors).reduce((result, color) => {
        if (color.value) result[color.var] = color.value;
        return result;
      }, {})
    },
  },
  async mounted() {
    const payload = {
              "is_scratch": true,
              "self_service": true,
              "due_diligence_level": "lightray"
            }
            const info = await ax.post(`client-auth/init`, {});
            // const client_id={"client_id":"b356b4eb-6fea-4445-bca1-d6717e7dca67"};
            const id=info.data.client_id;
            VueCookies.set("client_id",id);
            const clientId = VueCookies.get("client_id");
            const client_id = {"client_id" : clientId};
            const url = `/user/saas-token`;
            const saasToken = await axios.post(url,client_id)
            const {data} = await getSaasUrl(payload,saasToken.data.token);
            this.case_url = data.case_url;
  },
  async created () {
    await this.fetchNewClientId()
    await this.fetchUIConf(this.getClientId)
  },
     methods: {
    ...mapActions(["fetchUIConf"]),
    async fetchNewClientId() {
      if (!this.$store.getters.getClientId) {
        await this.$store.dispatch("fetchClientId");
        // this.tenant_id = this.$store.getters.getTenantId;
      }
      await this.fetchDashboardNotification()
      // try {
      //   const { data } = await axios.post(`user-auth/init`)
      //   if(data){
      //     console.log('data.tenant_id :>> ', data.tenant_id);
      //     this.$store.dispatch("setTenantId", data.tenant_id)
          // if (data.effective_role === 'tenant_super_admin') this.$router.push({path: '/field-table'})
      //   }
        
      // } catch (error) {
      //   console.log(error.message || `Error fetching tenant ID `);        
      // }
    },
    async fetchDashboardNotification(){
      try{
        const { data } = await axios.get(`dashboard-notifications/client/count`)
        this.notificationCount = data.data
      }
      catch(error){
        console.log(" error", error)
      }
    }

  }
}
</script>
<style>
</style>